<!-- academicInformation -->
<template>
  <div class="academicInformation">
    <mainPage :type="2"></mainPage>
  </div>
</template>

<script>
import mainPage from './components/mainPage'
export default {
  name: 'AcademicInformation',
  data() {
    return {}
  },
  props: {},
  components: { mainPage },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
